import Footer from '../common/Footer';
import Error from '../common/Error';

export default function ErrorPage() {
    return (
        <main className="main">
            <Error/>

            <Footer/>
        </main>
    );
}