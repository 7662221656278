import './Loader.css';

function Loader(props) {
    const text = props.text ?? 'Загрузка...';

    const textContainer = <p className="text">{text}</p>

    return (
        <div className="loader-container">
            {textContainer}
            <div className="loader"></div>
        </div>
    );
}

export default Loader;
