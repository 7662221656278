import './Footer.css';
import {Link, useParams} from 'react-router-dom';

function Footer() {
    const { id } = useParams();
    return (
        <footer className="footer">
            <Link to={id ? `/${id}/help`: '/help'}  className="btn btn_link">Поддержка</Link>
        </footer>
    );
}

export default Footer;
