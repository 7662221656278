import './List.css';

import {Link, useParams} from 'react-router-dom';

function List() {
    const {id} = useParams();

    return (
        <section className="section">
            <h1 className="title">Выберете тип контента</h1>

            <div className="content-list">
                <Link to={`/${id}/link`} className="btn">
                        <svg className="btn-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.3 13.35a1 1 0 0 1-.7-.29 1 1 0 0 1 0-1.41l2.12-2.12a2 2 0 0 0 0-2.83L17.3 5.28a2.06 2.06 0 0 0-2.83 0L12.35 7.4A1 1 0 0 1 10.94 6l2.12-2.12a4.1 4.1 0 0 1 5.66 0l1.41 1.41a4 4 0 0 1 0 5.66L18 13.06a1 1 0 0 1-.7.29ZM8.11 21.3a4 4 0 0 1-2.83-1.17l-1.41-1.41a4 4 0 0 1 0-5.66L6 10.94a1 1 0 0 1 1.4 1.41l-2.12 2.12a2 2 0 0 0 0 2.83l1.42 1.42a2.06 2.06 0 0 0 2.83 0l2.12-2.12a1 1 0 1 1 1.41 1.4l-2.12 2.12a4 4 0 0 1-2.83 1.18Z"
                                fill="white"/>
                            <path
                                d="M8.82 16.18a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42l6.37-6.36a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42l-6.37 6.36a1 1 0 0 1-.7.29Z"
                                fill="white"/>
                        </svg>
                    Ссылка
                </Link>
                <Link to={`/${id}/video`} className="btn">
                        <svg className="btn-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path
                                fill={"white"}
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM10.622 8.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z"/>
                        </svg>
                    Видео
                </Link>
                <Link to={`/${id}/instruction`} className="btn btn_info">
                    <svg className="btn-icon" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="white"
                            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/>
                    </svg>
                    Инструкция
                </Link>
            </div>
        </section>
    );
}

export default List;
