import {Link, useNavigate, useParams} from 'react-router-dom';
import * as React from 'react';
import {useState} from 'react';
import Alert from '../../common/Alert';

export default function YourLink() {
    const {id} = useParams();
    const navigate = useNavigate();

    const [isApprove, setIsApprove] = useState(false);
    const [link, setLink] = useState(null);
    const [error, setError] = useState(null);

    if (isApprove) {
        return <div className="section">
            <h1 className="title">Подтвердить?</h1>
            <p className="text">Ваша ссылка:
                <a href={link} className="approve-link" target="_blank" rel="noreferrer">{link}</a>
                будет открываться при сканировании QR кода
            </p>
            <p className="text">Вы не сможете сделать изменения после подтверждения</p>

            <button className="btn" onClick={setIsApprove(false)}>Подтвердить</button>
            <button className="btn btn_second">Отменить</button>
        </div>
    }

    function form(event) {
        event.preventDefault();

        fetch(`/api/qr/${id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "type": "link",
                "url": link,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    navigate(`/${id}/success`);
                } else {
                    throw new Error(data.error);
                }
            })
            .catch((err) => {
                setError(`Что то пошло не так, попройте еще раз, или напишите в поддержку`);
            });
    }

    let alert;

    if (error) {
        alert = <Alert text={error} />;
    }

    return (
        <section className="section">
            <h1 className="title">Укажите вашу ссылку</h1>
            <p className="text">При сканировании открытки будет происходить переход по ссылке.
                Это может быть ваше видео или пост в социальных сетях, или любая ссылка.</p>

            <form className="form" onSubmit={form}>
                <input
                    type="text"
                    className="input"
                    placeholder="Вставте ссылку"
                    name="link"
                    onChange={(e) => setLink(e.target.value)}
                    required={true}
                    pattern="http(s)?://.+"
                    title="Формат ссылки должен начинаться с https:// или http://"
                />
                <p className="text">Вы не сможете сделать изменения после подтверждения</p>
                {alert}
                <button className="btn" type="submit">Подтвердить</button>
            </form>

            <Link to={`/${id}/list`} className="btn btn_second">Назад</Link>
        </section>
    );
}
