import {createBrowserRouter, redirect} from 'react-router-dom';
import App from './App';
import ErrorPage from './routes/ErrorPage';
import List from './routes/List';
import Video from './routes/types/Video';
import YourLink from './routes/types/YourLink';
import React from 'react';
import Help from './routes/Help';
import VideoPreview from './routes/types/VideoPreview';
import Success from './common/Succes';
import Loader from './common/Loader';
import Error from './common/Error';
import Instruction from './routes/Instruction';


export async function loader({ params }) {
    const id = params.id;
    const response  = await fetch(`api/qr/${id}`);
    const qr = await response.json();

    console.log('qr', qr);

    if (!qr) {
        return redirect('list');
    }

    if (qr?.error) {
        return redirect('error');
    }

    if (qr?.type === 'link') {
        window.location.replace(qr.url);
    }

    if (qr?.type === 'video') {
        localStorage.setItem(id, JSON.stringify(qr));
        return redirect('video-preview');
    }

    return { qr };
}

export const routers = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Error/>,
            },
            {
                path: "/:id",
                loader: loader,
                element: <Loader />,
            },
            {
                path: "/:id/video-preview",
                element: <VideoPreview />,
            },
            {
                path: "/:id/list",
                element: <List />,
            },
            {
                path: "/:id/link",
                element: <YourLink />,
            },
            {
                path: "/:id/video",
                element: <Video />,
            },
            {
                path: "/:id/help",
                element: <Help />,
            },
            {
                path: "/:id/instruction",
                element: <Instruction />,
            },
            {
                path: "/instruction",
                element: <Instruction />,
            },
            {
                path: "/help",
                element: <Help />,
            },
            {
                path: "/:id/success",
                element: <Success />,
            },
            {
                path: "/:id/error",
                element: <Error />,
            },
            {
                path: "/error",
                element: <Error />,
            },
        ]
    },
]);