import {Outlet} from 'react-router-dom';
import Footer from './common/Footer';

function App() {
  return (
    <main className="main">
        <Outlet />
        <Footer />
    </main>
  );
}

export default App;
