import {Link, useParams, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import * as React from 'react';
import Alert from '../../common/Alert';
import Loader from '../../common/Loader';

function Video() {
    const {id} = useParams();
    const [video, setVideo] = useState();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const file = e.target.files[0];

        if (file.size > 500 * 1024 * 1024) {
            alert('Файл слишком большой. Максимальный размер файла 300MB. ' +
                'Напишите нам в поддержку если вам нужно загрузить большой файл.');

            e.target.value = null;
            return;
        }

        setVideo(file);
    }

    const loadVideo = () => {
        setLoading(true);
        const formData  = new FormData();

        formData.append('video', video);

        fetch(`/api/qr/video/${id}`, {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    navigate(`/${id}/success`);
                } else {
                    throw new Error(data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(`Что то пошло не так, попройте еще раз, или напишите в поддержку`);
            });
    };

    let alertComponent;

    if (error) {
        alertComponent = <Alert text={error} />;
    }

    if (isLoading) {
        return <Loader text="Идет загрузка, дождитесь окончания загрузки!"/>;
    }

    return (
        <section className="section">
            <h1 className="title">Загрузите ваше видео</h1>
            <p className="text">Ваше видео будет отображаться при сканировании открытки.</p>
            <p className="text">Максимальный размер файла 500MB.</p>
            <input type="file" onChange={handleChange} accept="video/mp4,video/x-m4v,video/*"/>

            <p className="text">Вы не сможете сделать изменения после загрузки</p>

            {alertComponent}

            <button className="btn" onClick={loadVideo} disabled={!video}>Загрузить</button>
            <Link to={`/${id}`} reloadDocument={true} className="btn btn_second">Назад</Link>
        </section>
    );
}

export default Video;
