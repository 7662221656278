import './VideoPreview.css';
import React from 'react';
import {useParams} from 'react-router-dom';

function VideoPreview() {
    const {id} = useParams();
    const data = JSON.parse(localStorage.getItem(id));

    const file = `/files/${data.src}`;

    return (
        <section className="section fade-in">
            <video className="video" controls preload={'auto'} src={file}></video>
            <a href={file} className="btn" download={true}>Скачать</a>
        </section>
    );

}

export default VideoPreview;
