import {Link, useParams} from 'react-router-dom';

export default function Help() {
    const { id } = useParams();

    const code = id ? <p className="text">Ваш код: <b>{id}</b></p> : null;

    return (
        <div className="section">
            <h1 className="title">Если у вас есть вопросы напишите нам</h1>

            {code}

            <a href="https://t.me/qr24ru" className="btn" target="_blank">Телеграм</a>
            <a href="https://vk.me/sj_tattoo" className="btn" target="_blank">Вк</a>

            <Link to={id ? `/${id}` : '/'} reloadDocument={true} className="btn btn_second">Назад</Link>
        </div>
    );
}