import {Link, useParams} from 'react-router-dom';

export default function Instruction() {
    const { id } = useParams();

    return (
        <div className="section">
            <h1 className="title">Инструкция</h1>

            <p className="text">
                <ol>
                    <li>Выберет тип контента, укажите ссылку или загрузите ваше видео</li>
                    <li>Дождитесь успешной загрузки вашего QR кода</li>
                    <li>QR готов к использованию</li>
                    <li>Если у вас остались вопросы можете спросить в поддержке</li>
                </ol>
            </p>

            <Link to={id ? `/${id}/help` : '/help'} reloadDocument={true} className="btn">Поддержка</Link>
            <Link to={id ? `/${id}` : '/'} reloadDocument={true} className="btn btn_second">Назад</Link>
        </div>
    );
}